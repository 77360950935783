import { default as indexdq2scyiCnDMeta } from "/home/runner/work/web-app/web-app/src/pages/15anos/index.vue?macro=true";
import { default as index463D14ZoLkMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/4-cadeiras/index.vue?macro=true";
import { default as inscricaok0Qf98EAldMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/4-cadeiras/inscricao.vue?macro=true";
import { default as inscricoescxXVpzxBoBMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/inscricoes.vue?macro=true";
import { default as politica_45privacidadeAGzluVBKe6Meta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/politica-privacidade.vue?macro=true";
import { default as indexV3l6QrTxmhMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-components@1.5.0_@cssninja+nuxt-toaster@0.3.12_@shuriken-ui+nuxt@3.6.0_vue@3.4.38/node_modules/@ibcampusmanaus/web-app-components/src/pages/index.vue?macro=true";
import { default as desligarMAYZL42arOMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/culto/desligar.vue?macro=true";
import { default as indexL3psrwTi4WMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/culto/index.vue?macro=true";
import { default as ligartRgva9Q4oIMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/culto/ligar.vue?macro=true";
import { default as indexYzDkpdLMsbMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/index.vue?macro=true";
import { default as indexW5BpTR96EDMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/luzes/index.vue?macro=true";
import { default as indexFgQCR3FGVtMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/projecao/index.vue?macro=true";
import { default as desligarFe284QshT9Meta } from "/home/runner/work/web-app/web-app/src/pages/levitas/som/desligar.vue?macro=true";
import { default as indexAnu8tltSOtMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/som/index.vue?macro=true";
import { default as ligarb2TEHpeGuNMeta } from "/home/runner/work/web-app/web-app/src/pages/levitas/som/ligar.vue?macro=true";
import { default as levitas1oudxqKcq1Meta } from "/home/runner/work/web-app/web-app/src/pages/levitas.vue?macro=true";
import { default as _2024eoM40zU7YKMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.15.0_@ibcampusmanaus+web-app-commons@1.5.1_@ibcampusmanaus+w_rh3cjrcnlj35kgkmync2qqc3wi/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/2024.vue?macro=true";
import { default as respostasuZM6Ta7CHFMeta } from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.15.0_@ibcampusmanaus+web-app-commons@1.5.1_@ibcampusmanaus+w_rh3cjrcnlj35kgkmync2qqc3wi/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/respostas.vue?macro=true";
export default [
  {
    name: "15anos",
    path: "/15anos",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/15anos/index.vue").then(m => m.default || m)
  },
  {
    name: "evento-4-cadeiras",
    path: "/evento/4-cadeiras",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/4-cadeiras/index.vue").then(m => m.default || m)
  },
  {
    name: "evento-4-cadeiras-inscricao",
    path: "/evento/4-cadeiras/inscricao",
    meta: inscricaok0Qf98EAldMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/4-cadeiras/inscricao.vue").then(m => m.default || m)
  },
  {
    name: "evento-inscricoes",
    path: "/evento/inscricoes",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/inscricoes.vue").then(m => m.default || m)
  },
  {
    name: "evento-politica-privacidade",
    path: "/evento/politica-privacidade",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-events@1.4.0/node_modules/@ibcampusmanaus/web-app-events/src/pages/evento/politica-privacidade.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    meta: indexV3l6QrTxmhMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-components@1.5.0_@cssninja+nuxt-toaster@0.3.12_@shuriken-ui+nuxt@3.6.0_vue@3.4.38/node_modules/@ibcampusmanaus/web-app-components/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: levitas1oudxqKcq1Meta?.name,
    path: "/levitas",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas.vue").then(m => m.default || m),
    children: [
  {
    name: "levitas-culto-desligar",
    path: "culto/desligar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/culto/desligar.vue").then(m => m.default || m)
  },
  {
    name: "levitas-culto",
    path: "culto",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/culto/index.vue").then(m => m.default || m)
  },
  {
    name: "levitas-culto-ligar",
    path: "culto/ligar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/culto/ligar.vue").then(m => m.default || m)
  },
  {
    name: "levitas",
    path: "",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/index.vue").then(m => m.default || m)
  },
  {
    name: "levitas-luzes",
    path: "luzes",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/luzes/index.vue").then(m => m.default || m)
  },
  {
    name: "levitas-projecao",
    path: "projecao",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/projecao/index.vue").then(m => m.default || m)
  },
  {
    name: "levitas-som-desligar",
    path: "som/desligar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/som/desligar.vue").then(m => m.default || m)
  },
  {
    name: "levitas-som",
    path: "som",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/som/index.vue").then(m => m.default || m)
  },
  {
    name: "levitas-som-ligar",
    path: "som/ligar",
    component: () => import("/home/runner/work/web-app/web-app/src/pages/levitas/som/ligar.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "pesquisa-2024",
    path: "/pesquisa/2024",
    meta: _2024eoM40zU7YKMeta || {},
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.15.0_@ibcampusmanaus+web-app-commons@1.5.1_@ibcampusmanaus+w_rh3cjrcnlj35kgkmync2qqc3wi/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/2024.vue").then(m => m.default || m)
  },
  {
    name: "pesquisa-respostas",
    path: "/pesquisa/respostas",
    component: () => import("/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-survey@1.15.0_@ibcampusmanaus+web-app-commons@1.5.1_@ibcampusmanaus+w_rh3cjrcnlj35kgkmync2qqc3wi/node_modules/@ibcampusmanaus/web-app-survey/src/pages/pesquisa/respostas.vue").then(m => m.default || m)
  }
]