export const appHead = {"meta":[{"name":"viewport","content":"width=device-width,initial-scale=1,viewport-fit=cover"},{"charset":"utf-8"},{"hid":"description","name":"description","content":"Uma igreja pra tornar Cristo conhecido","tagPriority":-10},{"property":"og:title","content":"Igreja Batista Campus Manaus","tagPriority":-10},{"property":"og:description","content":"Tornando Cristo conhecido","tagPriority":-10},{"property":"og:type","content":"website","tagPriority":-10},{"property":"og:image","content":"https://ibcampusmanaus.org/assets/bg.90919278.jpeg","tagPriority":-10},{"property":"og:image:width","content":"1280","tagPriority":-10},{"property":"og:image:height","content":"718","tagPriority":-10},{"property":"og:site_name","content":"Campus Manaus","tagPriority":-10}],"link":[],"style":[],"script":[],"noscript":[],"viewport":"width=device-width,initial-scale=1,viewport-fit=cover","title":"Igreja Batista Campus Manaus","htmlAttrs":{"lang":"pt-br"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false