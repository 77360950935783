import payload_plugin_CGldRgof1K from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_firebase-functions@4.9.0_f_y5qfmhn7zegwmlhtcv4bswb7na/node_modules/nuxt-vuefire/dist/runtime/payload-plugin.js";
import revive_payload_client_XjWkRlrGI3 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_YhnpgEe8Yn from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_oThWqaWovU from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_0Ugwy0zWcm from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-site-config@2.2.15_vite@5.4.2_vue@3.4.38/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_JrlWRP9G7R from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_kgekW8csY2 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_gLf42rdv9q from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_Yz29lUzghf from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_SSaN7Wigyg from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@pinia+nuxt@0.5.4_typescript@5.5.4_vue@3.4.38/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/web-app/web-app/.nuxt/components.plugin.mjs";
import prefetch_client_QUckAfeBgP from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt@3.13.0_@types+node@20.16.2_eslint@9.9.1_typescript@5.5.4_vite@5.4.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_X9w0aNS2iH from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxt+icon@1.5.0_vite@5.4.2_vue@3.4.38/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import plugin_client_y8wE6HgTD4 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_UCPbtnuHxD from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@cssninja+nuxt-toaster@0.3.12_vue@3.4.38/node_modules/@cssninja/nuxt-toaster/dist/runtime/plugin.mjs";
import plugin_client_H10fe75GvX from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_firebase-functions@4.9.0_f_y5qfmhn7zegwmlhtcv4bswb7na/node_modules/nuxt-vuefire/dist/runtime/app/plugin.client.js";
import vuefire_auth_plugin_client_cCwkwh1j5u from "/home/runner/work/web-app/web-app/.nuxt/vuefire-auth-plugin.client.mjs";
import vuefire_plugin_8cq4FnzXKb from "/home/runner/work/web-app/web-app/.nuxt/vuefire-plugin.mjs";
import plugin_client_wIVt2M4RwE from "/home/runner/work/web-app/web-app/node_modules/.pnpm/nuxt-vuefire@1.0.4_@firebase+app-types@0.9.2_firebase-admin@12.4.0_firebase-functions@4.9.0_f_y5qfmhn7zegwmlhtcv4bswb7na/node_modules/nuxt-vuefire/dist/runtime/app-check/plugin.client.js";
import directives_PLPyyCEDNE from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@spalla-dev+tairo@1.2.0_@cssninja+nuxt-toaster@0.3.12_@shuriken-ui+nuxt@3.6.0/node_modules/@spalla-dev/tairo/plugins/directives.ts";
import maska_Bx90Ktiz79 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-components@1.5.0_@cssninja+nuxt-toaster@0.3.12_@shuriken-ui+nuxt@3.6.0_vue@3.4.38/node_modules/@ibcampusmanaus/web-app-components/src/plugins/maska.ts";
import api_GuEvgZZAx4 from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-commons@1.5.1_vue@3.4.38/node_modules/@ibcampusmanaus/web-app-commons/src/plugins/api.ts";
import remoteConfig_client_OVkTV5sUnn from "/home/runner/work/web-app/web-app/node_modules/.pnpm/@ibcampusmanaus+web-app-commons@1.5.1_vue@3.4.38/node_modules/@ibcampusmanaus/web-app-commons/src/plugins/remoteConfig.client.ts";
export default [
  payload_plugin_CGldRgof1K,
  revive_payload_client_XjWkRlrGI3,
  unhead_YhnpgEe8Yn,
  router_oThWqaWovU,
  _0_siteConfig_0Ugwy0zWcm,
  payload_client_JrlWRP9G7R,
  navigation_repaint_client_kgekW8csY2,
  check_outdated_build_client_gLf42rdv9q,
  chunk_reload_client_Yz29lUzghf,
  plugin_vue3_SSaN7Wigyg,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QUckAfeBgP,
  plugin_X9w0aNS2iH,
  plugin_client_y8wE6HgTD4,
  plugin_UCPbtnuHxD,
  plugin_client_H10fe75GvX,
  vuefire_auth_plugin_client_cCwkwh1j5u,
  vuefire_plugin_8cq4FnzXKb,
  plugin_client_wIVt2M4RwE,
  directives_PLPyyCEDNE,
  maska_Bx90Ktiz79,
  api_GuEvgZZAx4,
  remoteConfig_client_OVkTV5sUnn
]